import styled from '@emotion/styled'
import { TriangleRight } from 'app/components/Icons'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  list?: string[]
  languageCode: string
}

export const Includes = memo(function Includes({ list, languageCode }: Props) {
  if (!list) {
    return null
  }

  return (
    <Container>
      <Title>{useVocabularyData('include-offers-title', languageCode)}</Title>
      {list
        ? list.map((item: any, index) => (
            <Item key={index}>
              <TriangleRight /> {item.label}
            </Item>
          ))
        : null}
    </Container>
  )
})

const Container = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  margin-top: 3.9375rem;
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 2rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
`

const Item = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.1875rem;
  margin-top: 1.25rem;
  padding-left: 1.125rem;
  position: relative;

  svg {
    width: auto;
    height: 0.5rem;
    fill: ${({ theme }) => theme.colors.variants.neutralDark4};
    position: absolute;
    top: 0.375rem;
    left: 0;
  }
`
