import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Benefits } from './Benefits'
import { Head } from './Head'
import { Includes } from './Includes'
import { Sidebar } from './Sidebar'
import { Terms } from './Terms'

export interface Props {
  benefits: string[]
  benefitsTitle?: string
  description?: string
  pricefrom?: string
  IBEID?: number
  includes?: string[]
  languageCode: string
  languagePrefix?: string | null
  requestButtonURL?: string
  subtitle?: string
  terms?: string
  title: string
  validity?: string
}

export const OfferIntro = memo(function OfferIntro({
  benefits,
  benefitsTitle,
  description,
  pricefrom,
  IBEID,
  includes,
  languageCode,
  languagePrefix,
  requestButtonURL,
  subtitle,
  terms,
  title,
  validity,
}: Props) {
  return (
    <Container>
      <Head
        languageCode={languageCode}
        pricefrom={pricefrom}
        validity={validity}
      />

      <Wrapper row stretch wrap>
        <LeftSide>
          <Title>{title}</Title>
          {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
          {description ? (
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          ) : null}

          <Includes list={includes} languageCode={languageCode} />

          <Terms description={terms} languageCode={languageCode} />
        </LeftSide>
        <RightSide>
          <Sidebar
            IBEID={IBEID}
            languageCode={languageCode}
            languagePrefix={languagePrefix}
            requestButtonURL={requestButtonURL}
          />
          <Benefits list={benefits} title={benefitsTitle} />
        </RightSide>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section``

const Wrapper = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
`

const LeftSide = styled.div`
  width: 50%;
  padding: 5.625rem 6.667vw 5.25rem 8.056vw;

  @media (max-width: 1199px) {
    padding: 3.75rem 3.333vw 4.6875rem 6.056vw;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding: 3.75rem 1.875rem 1.875rem;
  }
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  line-height: 2.375rem;

  @media (max-width: 1023px) {
    font-size: 1.625rem;
    line-height: 2rem;
  }
`

const Subtitle = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  letter-spacing: 0.2rem;
  line-height: 1.25rem;
  margin-top: 0.75rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    margin-top: 0.5rem;
    letter-spacing: 0.15rem;
  }

  @media (max-width: 767px) {
    letter-spacing: 0.1rem;
    font-size: 0.8125rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  margin-top: 2.5rem;

  @media (max-width: 1023px) {
    line-height: 1.75rem;
    margin-top: 1.5rem;
  }
`

const RightSide = styled.div`
  width: 50%;
  border-left: 0.0625rem solid
    ${({ theme }) => theme.colors.variants.neutralLight2};
  padding: 5rem 6.667vw 5rem 8.056vw;

  @media (max-width: 1199px) {
    padding: 3.125rem 3.333vw 4.375rem 6.056vw;
  }

  @media (max-width: 1023px) {
    border-left: none;
    width: 100%;
    padding: 0 1.875rem 3.75rem;
  }
`
