import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  languageCode: string
  pricefrom?: string
  validity?: string
}

export const Head = memo(function Head({
  languageCode,
  pricefrom,
  validity,
}: Props) {
  if (!pricefrom && !validity) {
    return null
  }

  return (
    <Container dial={5} row stretch wrap>
      {validity ? (
        <LeftSide dial={5} row wrap>
          {validity ? <Validity>{validity}</Validity> : null}
        </LeftSide>
      ) : null}
      {pricefrom ? (
        <RightSide>
          <Pricefrom
            dangerouslySetInnerHTML={{
              __html: `${useVocabularyData(
                'starting-from',
                languageCode,
              )} ${pricefrom}`,
            }}
          />
        </RightSide>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  text-align: center;

  > div {
    flex: 1;
    padding: 2.875rem 3.333vw 2.875rem;

    @media (max-width: 1199px) {
      padding: 2.25rem 3.333vw 2.25rem;
    }

    @media (max-width: 1023px) {
      padding: 2rem 1.875rem 2rem;
    }

    @media (max-width: 767px) {
      padding: 1.6875rem 1.875rem 1.6875rem;
      flex: auto;
    }
  }
`

const LeftSide = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;

  @media (max-width: 1199px) {
    line-height: 1.5rem;
  }

  @media (max-width: 767px) {
    font-size: 0.875rem;
    line-height: 1.125rem;
    width: 100%;
  }
`

const Validity = styled.div``

const RightSide = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralDark4};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  line-height: 1.75rem;

  b {
    font-weight: normal;
  }

  @media (max-width: 1199px) {
    line-height: 1.5rem;
  }

  @media (max-width: 1023px) {
    font-size: 1.25rem;
    line-height: 1.25rem;
  }

  @media (max-width: 767px) {
    font-size: 1.125rem;
    line-height: 1.125rem;
    width: 100%;
  }
`

const Pricefrom = styled.div``
