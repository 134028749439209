import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { IBEButton } from 'app/components/Common/Button/IBEButton'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  IBEID?: number
  languageCode: string
  languagePrefix?: string | null
  requestButtonURL?: string
}

export const Sidebar = memo(function Sidebar({
  IBEID,
  languageCode,
  languagePrefix,
  requestButtonURL,
}: Props) {
  return (
    <Container>
      <Wrapper>
        <Title>{useVocabularyData('offer-sidebar-title', languageCode)}</Title>
        {IBEID ? (
          <IBEButton
            IBEID={IBEID}
            languageCode={languageCode}
            languagePrefix={languagePrefix}
          />
        ) : null}
        {requestButtonURL ? (
          <Request
            label={useVocabularyData('request-info', languageCode)}
            URL={requestButtonURL}
            variant="outline-dark"
          />
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.div``

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 3.75rem 6.667vw;
  text-align: center;

  @media (max-width: 1023px) {
    padding: 3.125rem 1.875rem;
    > a {
      display: block;
      width: 100%;
      min-width: auto;
    }
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  line-height: 1.75rem;
  margin-bottom: 2.5rem;

  @media (max-width: 1023px) {
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-bottom: 1.875rem;
  }
`

const Request = styled(Button)`
  margin-top: 0.75rem;
`
